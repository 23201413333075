var ClipboardJS = require("clipboard");

$(document).on('ready', () => {

  var btns = document.querySelectorAll('.clipboard-btn');
  for (var i = 0; i < btns.length; i++) {
    btns[i].addEventListener('mouseleave', clearCheck);
    btns[i].addEventListener('blur', clearCheck);
  }

  function clearCheck(e) {
    e.fromElement.innerHTML = "content_copy";
  }

  function showCheck(e) {
    e.innerHTML = "check";
  }

  var clipboard = new ClipboardJS('.clipboard-btn');
  clipboard.on('success', function(e) {
    e.clearSelection();

    console.info('Text:', e.text);
    console.info('Trigger:', e.trigger);
    
    showCheck(e.trigger)
  });
});
